import React from 'react';
import PrimaryButton from '../button/PrimaryButton';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import axios from 'axios';
import { API_ENDPOINTS } from '../../utils/constants';
import Modal from '../modals/Modal';
import './DatastreamOptions.css';

const DatastreamOptions = () => {
  const dispatch = useAppDispatch();
  const datastream = useAppSelector((state) => state.datastream);
  const sendDatastreamUpdate = (dispatch: any, datastream: any, option: string) => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    let message = '';
    if (option !== 'reset' && option !== 'delete') {
      return Promise.reject(new Error('Invalid option'));
    }
    if (option === 'delete') {
      message = 'Datastream data has been deleted';
    } else {
      message = 'Datastream state has been reset';
    }
    axios
      .post(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/streams/update`, {
        org: datastream.org_id,
        datastream: datastream.id,
        option: option,
      })
      .then((response) => {
        if (response.data && response.data.type === 'success') {
          dispatch({ type: 'DISPLAY_SUCCESS', payload: { reason: message } });
        } else {
          throw new Error('Failed to update datastream');
        }
      })
      .catch((error) => {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error.response?.data?.error } });
      })
      .finally(() => {
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      });
  };
  return (
    <div>
      <h1>Options</h1>
      <div className="modal">
        <Modal size={'tiny'} buttonText={`Delete data from ${datastream?.name}`} children={'This action is permanent and irreversible'} confirmation={true} handleClick={() => sendDatastreamUpdate(dispatch, datastream, 'delete')} />
        {/* <PrimaryButton text={`Delete data from ${datastream?.name}`} handleClick={() => sendDatastreamUpdate(dispatch, datastream, 'delete')} /> */}
      </div>
      <div className="modal">
        <Modal size={'tiny'} buttonText={`Reset State in ${datastream?.name}`} children={'This action is permanent and irreversible'} confirmation={true} handleClick={() => sendDatastreamUpdate(dispatch, datastream, 'reset')} />
        {/* <PrimaryButton text={`Reset state in ${datastream?.name}`} handleClick={() => sendDatastreamUpdate(dispatch, datastream, 'reset')} /> */}
      </div>{' '}
    </div>
  );
};

export default DatastreamOptions;
